import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { Spinner } from '@material-tailwind/react'
import { useState } from 'react'
import PhoneInput, { Value } from 'react-phone-number-input'
import ShowToast from '../../../components/alerts/all_toasts'
import { StorageBox } from '../../../helpers/stroage'
// import VerifyOTPModal from './verifyOTPModal';
import { Link } from 'react-router-dom'
import CloseVerificationModal from './CloseVerificationModal'
import { RouteConstants } from '../../../helpers/RouteConstants'
import Button from '../../../components/Button'
import PinInput from 'react-pin-input'
import { useNavigate } from 'react-router-dom'
import BaseService from '../../../helpers/baseServices'

interface stepProps {
  dataFromStep1: any
  goBack: () => void
}

const SecondStep = ({ dataFromStep1, goBack }: stepProps) => {
  const navigate = useNavigate()
  const [isError, setIsError] = useState(false)
  const [isBusy, setIsBusy] = useState(false)
  const [theUserData, setTheUserData] = useState<any>(null)
  const [closeVerifyDialog, openCloseVerifyDialog] = useState(false)
  const [emergency1, setEmergency1] = useState<Value | undefined>('')
  const [emergency2, setEmergency2] = useState<Value | undefined>('')
  const [thePin, setThePin] = useState('')
  const [confirmPin, setConfirmPin] = useState('')
  const [wrongPin, setWrongPins] = useState(false)
  const [code, setCode] = useState<number | any>()

  //openVerificationModal
  const [openVerify, setOpenVerify] = useState(false)
  const handleOpenVerify = () => {
    setOpenVerify(true)
  }
  // const handlecCloseVerify = () => {
  //   setOpenVerify(false);
  // };

  //handleCreateAccount
  const handleCreateAccount = async () => {
    let formatPhoneNumber1 = emergency1?.replace(/\s+/g, '')
    let formatPhoneNumber2 = emergency2?.replace(/\s+/g, '')

    if (!thePin || !confirmPin) {
      setIsError(true)
    } else if (thePin !== confirmPin) {
      setWrongPins(true)
    } else {
      let finalData = {
        ...dataFromStep1,
        ice1_phone: formatPhoneNumber1,
        ice2_phone: formatPhoneNumber2,
        pin: thePin
      }
      // console.log(finalData)
      setTheUserData(finalData)
      setIsBusy(true)
      try {
        const response = await BaseService.post_api_auth(
          `${RouteConstants.SIGN_UP_ROUTE}`,
          finalData
        )
        // console.log(response?.data)
        StorageBox.saveAccessToken(response?.data?.payload?.token)
        StorageBox.saveUserData(response?.data?.payload)

        handleOpenVerify()
      } catch (error: any) {
        // console.log(error)
        setIsBusy(false)
        ShowToast.error_toast(error?.response?.data?.message)
      } finally {
        setIsBusy(false)
      }
    }
  }

  //handleOnPinComplete
  const handleOnPinComplete = () => {
    // console.log(value)
    let data = {
      phone: theUserData?.phone,
      code
    }
    // console.log(data)
    setIsBusy(true)
    BaseService.post_api_auth(`${RouteConstants.VERIFY_OTP}`, data)
      .then((response) => {
        // console.log(response?.data);

        ShowToast.success_toast('Sign Up Successful. Please Sign In')
        StorageBox.saveUserData(response?.data?.payload)
        navigate('/')
      })
      .catch((error) => {
        // console.error(error);
        //handleCloseDrawer()
        ShowToast.error_toast(error?.response?.data?.message)
        setIsBusy(false)
      })
  }

  //handleResend
  const handleResend = () => {
    let data = {
      phone: theUserData?.phone
    }
    setIsBusy(true)
    BaseService.post_api_auth(`${RouteConstants.SEND_OTP}`, data)
      .then((response) => {
        // console.log(response?.data);
        if (response?.status === 200) {
          ShowToast.success_toast('Verification code sent')
        }
        setIsBusy(false)
      })
      .catch((error) => {
        // console.error(error);
        //handleCloseDrawer()
        ShowToast.error_toast(error?.response?.data?.message)
        setIsBusy(false)
      })
  }

  return (
    <>
      {openVerify === false ? (
        <div>
          <button onClick={() => goBack()}>
            <ChevronLeftIcon className='w-6 h-6' />
          </button>
          <div className='w-full mt-5'>
            <div className='header-text-3'>Create Pin</div>
            <div className='body-text-2 pt-1'>
              Create a pin to secure your account.
            </div>
          </div>

          <div className='flex flex-col mt-8 relative'>
            <small className={`${isError ? 'text-red-500' : ''} mb-2`}>
              Your new pin
            </small>
            <input
              className='input'
              type='password'
              value={thePin}
              maxLength={4}
              onChange={(e) => setThePin(e.target.value)}
              placeholder='Enter your new pin'
            />
            {/* <LockClosedIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>

          <div className='flex flex-col mt-8 relative'>
            <small className={`${isError ? 'text-red-500' : ''} mb-2`}>
              Confirm your pin
            </small>
            <input
              className='input'
              type='password'
              value={confirmPin}
              maxLength={4}
              onChange={(e) => setConfirmPin(e.target.value)}
              placeholder='Confirm your new pin'
            />
            {/* <LockClosedIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>
          {wrongPin && <div className='text-red-500 mt-2'>Pin mismatch!</div>}

          <div className='flex flex-col mt-8 relative'>
            <small className={`${isError ? 'text-red-500' : ''} mb-2`}>
              Emergency Number 1
            </small>
            <PhoneInput
              className='input'
              defaultCountry='GH'
              inputStyle={{ width: '100%', height: '50px' }}
              value={emergency1}
              onChange={(phone) => setEmergency1(phone)}
              numberInputProps={{
                className: 'focus:outline-none'
              }}
            />
          </div>

          <div className='flex flex-col mt-4 relative'>
            <small className={`${isError ? 'text-red-500' : ''} mb-2`}>
              Emergency Number 2
            </small>
            <PhoneInput
              className='input'
              defaultCountry='GH'
              inputStyle={{ width: '100%', height: '50px' }}
              value={emergency2}
              onChange={(phone) => setEmergency2(phone)}
              numberInputProps={{
                className: 'focus:outline-none'
              }}
            />
          </div>

          <div className='mt-[30px] flex justify-center items-center'>
            <Button
              className='h-[55px] bg-primary text-white rounded-[8px] w-full flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400'
              disabled={isBusy}
              onClick={() => handleCreateAccount()}
            >
              {isBusy ? <Spinner /> : <span>Continue</span>}
            </Button>
          </div>
          <div className='mt-5 flex items-center gap-2'>
            <p>Already have an account?</p>
            <Link className='btn-subtle' to='/'>
              Sign In
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <button
            onClick={() => {
              openCloseVerifyDialog(true)
            }}
          >
            <ChevronLeftIcon className='w-6 h-6' />
          </button>
          <div className='w-full mt-5'>
            <div className='header-text-3'>Verify number</div>
            <div className='body-text-2 pt-1'>
              Enter the 6-digit code sent to {theUserData?.phone}
            </div>
          </div>

          <div className='mt-[4rem]'>
            <PinInput
              length={6}
              initialValue=''
              secret
              secretDelay={100}
              type='custom'
              inputMode='number'
              inputStyle={{
                borderColor: '#939291',
                backgroundColor: '#fff',
                borderRadius: '8px'
              }}
              inputFocusStyle={{ borderColor: '#FF6200' }}
              onComplete={(value, index) => {
                setCode(value)
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </div>
          <div className='mt-5'>
            {isBusy ? (
              <Spinner color='green' className='h-8 w-8' />
            ) : (
              <>
                <div>
                  <p className='flex items-center gap-2'>
                    Didn’t receive code?{' '}
                    <span className='btn-subtle' onClick={() => handleResend()}>
                      Resend.
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
          <div className='mt-[100px] flex justify-center items-center'>
            <Button
              className='h-[55px] bg-primary text-white rounded-[8px] w-full flex justify-center items-center disabled:cursor-not-allowed disabled:bg-gray-400'
              disabled={isBusy}
              onClick={() => {
                handleOnPinComplete()
              }}
            >
              {isBusy ? <Spinner /> : <span>Create account</span>}
            </Button>
          </div>
        </div>
      )}

      <CloseVerificationModal
        isOpen={closeVerifyDialog}
        handleClosed={() => openCloseVerifyDialog(false)}
      />

      {/* {openVerify ? (
        <VerifyOTPModal
          userData={theUserData}
          openDrawer={openVerify}
          handleCloseDrawer={handlecCloseVerify}
        />
      ) : (
        <></>
      )} */}
    </>
  )
}

export default SecondStep

import { useState } from 'react'
// import { UserCircleIcon } from '@heroicons/react/24/outline';
import Button from '../../../components/Button'
// import PhoneInput, { Value } from 'react-phone-number-input'
import { PhoneInput } from 'react-international-phone'
import SecondStep from './secondStep'
import { Spinner } from '@material-tailwind/react'
import { Link } from 'react-router-dom'
// import { Input } from 'antd'

const FirstStep = () => {
  const [isBusy, setIsBusy] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [step, setStep] = useState(1)
  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')
  const [middleName, setMiddleName] = useState<string | undefined>()
  const [isError, setIsError] = useState(false)
  const [step1Data, setStep1Data] = useState<any>(null)

  //handleSignUp
  const handleSignUp = async () => {
    let formatPhoneNumber = phoneNumber?.replace(/\s+/g, '')
    if (!fName || !lName || !phoneNumber) {
      setIsError(true)
    } else {
      setIsError(false)
      setIsBusy(true)
      let firstData = {
        first_name: fName,
        last_name: lName,
        middle_name: middleName && middleName,
        phone: formatPhoneNumber,
        country_code: 'gh'
      }
      setTimeout(() => {
        setStep(2)
        setStep1Data(firstData)
        setIsBusy(false)
      }, 1000)
    }
  }

  return (
    <>
      {step === 1 ? (
        <div>
          <div className='w-full mt-5'>
            <div className='header-text-3'>Sign Up</div>
            <div className='body-text-2 pt-1'>Create an account with us.</div>
          </div>
          <div className='flex flex-col mt-8 relative'>
            <small className={`${isError ? 'text-red-500' : ''} mb-2 ml-1`}>
              Your first name
            </small>
            <input
              className='input'
              value={fName}
              onChange={(e) => setFName(e.target.value)}
              placeholder='Enter your first name'
            />
            {/* <UserCircleIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>
          <div className='flex flex-col mt-4 relative'>
            <small className={`mb-2 ml-1`}>Your middle name (optional)</small>
            <input
              className='input'
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
              placeholder='Enter your middle name'
            />
            {/* <UserCircleIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>
          <div className='flex flex-col mt-4 relative'>
            <small className={`${isError ? 'text-red-500' : ''} mb-2 ml-1`}>
              Your last name
            </small>
            <input
              className='input'
              value={lName}
              onChange={(e) => setLName(e.target.value)}
              placeholder='Enter your last name'
            />
            {/* <UserCircleIcon className='h-5 w-5 text-gray-600 absolute right-2 bottom-[10px]' /> */}
          </div>
          <div className='flex flex-col mt-4 relative'>
            <small className={`${isError ? 'text-red-500' : ''} mb-2 ml-1`}>
              Your mobile number
            </small>
            <PhoneInput
              inputClassName='input'
              defaultCountry='gh'
              hideDropdown
              placeholder='Enter your phone number'
              inputStyle={{
                width: '100%',
                height: '50px',
                fontSize: '18px',
                border: '1px solid #939291',
                padding: '8px',
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px'
              }}
              value={phoneNumber}
              onChange={(phone: any) => setPhoneNumber(phone)}
              disabled={isBusy}
            />
            {/* <PhoneInput
              className='input'
              defaultCountry='GH'
              inputStyle={{ width: '100%' }}
              value={phoneNumber}
              placeholder='Enter your mobile number'
              onChange={(phone: any) => setPhoneNumber(phone)}
              numberInputProps={{
                className: 'focus:outline-none'
              }}
            /> */}
            {/* <Input
              className='py-4 border border-gray-500 focus-within:border-gray-500 focus-within:shadow-none'
              maxLength={10}
              size='large'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              disabled={isBusy}
              placeholder='Enter Your Mobile Number'
              prefix={
                <div className='flex gap-1 items-center'>
                  <img src='/GH.png' alt='GH' />
                  <span>+233</span>
                </div>
              }
            /> */}
          </div>

          <div className='mt-[30px] flex justify-center items-center'>
            <Button
              width={'w-full'}
              disabled={isBusy}
              onClick={() => handleSignUp()}
            >
              {isBusy ? <Spinner /> : <span>Continue</span>}
            </Button>
          </div>
          <div className='mt-5 flex items-center gap-3'>
            <p>Already have an account?</p>
            <Link className='btn-subtle' to='/signin'>
              Sign In
            </Link>
          </div>
        </div>
      ) : (
        <>
          <SecondStep
            dataFromStep1={step1Data}
            goBack={() => {
              setStep(1)
            }}
          />
        </>
      )}
    </>
  )
}

export default FirstStep

import { useState, useEffect, Fragment } from 'react'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { ChevronDoubleDownIcon } from '@heroicons/react/24/solid'
import { Skeleton } from 'antd'
//import { Coupon } from '../../types'
import boxImg from '../../images/empty box.png'
import ChooseDrawer from '../home/chooseDrawer'
import CouponCard from '../home/CouponCard'
import FilterModal from './FilterModal'
import { RouteConstants } from '../../helpers/RouteConstants'
import BaseService from '../../helpers/baseServices'
import Button from '../../components/Button'
import TransferCouponModal from '../home/transferCouponModal'
import moment from 'moment'
import { StorageBox } from '../../helpers/stroage'
import DiscountCard from '../../components/DiscountCard'
import { Input, notification } from 'antd'
import { debounce } from 'lodash'
import MergeModal from './MergeModal'
import { Checkbox } from 'antd'
import CallToAction from '../../components/CallToAction'
import type { CheckboxProps } from 'antd'

import MergePrompt from './MergePrompt'
import ArchiveModal from './ArchiveModal'
import ArchivePrompt from './ArchivePrompt'

type FilterType = {
  f: string
  o: string
  p: any
  c?: string
}

const fuelButtons = [
  {
    id: 'all',
    name: 'All'
  },
  {
    id: 'super',
    name: 'Super'
  },
  {
    id: 'diesel',
    name: 'Diesel'
  },
  {
    id: 'v-power',
    name: 'V-Power'
  }
]

type NotificationType = 'success' | 'info' | 'warning' | 'error'

const Items = ({ title }: { title: any }) => {
  const [loading, setLoading] = useState(false)
  const [coupons, setCoupons] = useState<any>([])
  // const [couponsArray, setCouponsArray] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [fuelCoupons, setFuelCoupons] = useState<any>([])
  const [pageTitle, setPageTitle] = useState('')
  const [openFilter, setOpenFilter] = useState(false)
  const [openBottom, setOpenBottom] = useState(false)
  const [limit, setLimit] = useState(12)
  const [total, setTotal] = useState(0)
  const navigate = useNavigate()
  const [openTransfer, setOpenTransfer] = useState(false)
  const handleOpenTransfer = () => setOpenTransfer(true)
  const handleCloseTransfer = () => setOpenTransfer(false)
  const [fuelType, setFuelType] = useState('all')
  const [mergedCoupon, setMergedCoupon] = useState<any>({})
  const [ispining, setispining] = useState(false)
  const [selfOwnership, setSelfOwnership] = useState<boolean>(false)
  const [othersOwnership, setOthersOwnership] = useState<boolean>(false)
  const [anotherOwnership, setAnotherOwnership] = useState<boolean>(false)
  const [isMultipleOwnership, setIsMultipleOwnership] = useState<boolean>(false)
  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(new Date())
  const [userStats, setUserStats] = useState<any>({})
  const [isFilters, setIsFilters] = useState<boolean>(false)
  const [discountStats, setDiscountStats] = useState<any>({})
  const [selectedCoupons, setSelectedCoupons] = useState<any>([])
  const [showMergePrompt, setShowMergePrompt] = useState(false)
  const [isMerging, setIsMerging] = useState<'merge' | 'merging' | 'merged'>(
    'merge'
  )
  // const [archiveCoupons, setArchiveCoupons] = useState(false)
  const [showArchivePrompt, setShowArchivePrompt] = useState(false)
  const [isArchiving, setIsArchiving] = useState<
    'archive' | 'archiving' | 'archived'
  >('archive')
  const [isCouponUsed, setIsCouponUsed] = useState(false)
  const [isCouponPending, setIsCouponPending] = useState(false)
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const userInfo = StorageBox.retrieveUserData()
  const userId = userInfo?.user?.uid
  const [cta, setCTA] = useState(true)
  const [api, contextHolder] = notification.useNotification()
  const [bought4Others, setBought4Others] = useState<any>({})
  const [pendingPayment, setPendingPayment] = useState<any>({})

  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: 'Error Merging Coupons',
      description: message
    })
  }

  const getArchivedCoupons = async (
    multipleOwnership: boolean,
    fromDate: Date | null,
    toDate: Date | null,
    isSelf: boolean,
    isOthers: boolean,
    isAnother: boolean,
    isUsed: boolean,
    isPending: boolean,
    lim: number
  ) => {
    const filters: FilterType[] = []
    if (isFilters) {
      setIsFilters(false)
    }
    setLoading(true)

    if (isSelf) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isOthers) {
      filters.push(
        {
          f: 'owner.id',
          o: '!=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isAnother) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '!=',
          p: [`${userId}`]
        }
      )
    }

    if (isUsed) {
      filters.push({
        f: 'status.code',
        o: '=',
        p: 'USED'
      })
    }

    if (isPending) {
      filters.push({
        f: 'payment_status.code',
        o: '=',
        p: 'PENDING'
      })
    }

    if ((fromDate && toDate) || fromDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`
        ]
      })
    }

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_ARCHIVED_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(filters)}&limit=${lim}`
      )
      if (filters.length > 0) {
        setIsFilters(true)
      }
      const modifiedCouponData = data?.payload?.items.map((coupon: any) => ({
        ...coupon,
        checked: false
      }))
      // setCoupons(data?.payload?.items)
      localStorage.setItem('coupons', JSON.stringify(modifiedCouponData))
      setIsCheckedAll(false)
      setCoupons(modifiedCouponData)
      setFuelCoupons(modifiedCouponData)
      // setCouponsArray(data?.payload?.items)
      setTotal(data?.payload?.total)
    } catch (e) {
      //handle error
    } finally {
      setLoading(false)
    }
  }

  const getAvailableCoupons = async (
    multipleOwnership: boolean,
    fromDate: Date | null,
    toDate: Date | null,
    isSelf: boolean,
    isOthers: boolean,
    isAnother: boolean,
    isUsed: boolean,
    isPending: boolean,
    lim: number
  ) => {
    const filters: FilterType[] = []
    if (isFilters) {
      setIsFilters(false)
    }
    setLoading(true)

    if (isSelf) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isOthers) {
      filters.push(
        {
          f: 'owner.id',
          o: '!=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isAnother) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '!=',
          p: [`${userId}`]
        }
      )
    }

    if (isUsed) {
      filters.push({
        f: 'status.code',
        o: '=',
        p: 'USED'
      })
    }

    if (isPending) {
      filters.push({
        f: 'payment_status.code',
        o: '=',
        p: 'PENDING'
      })
    }

    if ((fromDate && toDate) || fromDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`
        ]
      })
    }

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_AVAILABLE_PAID_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(filters)}&limit=${lim}`
      )

      if (filters.length > 0) {
        setIsFilters(true)
      }
      const modifiedCouponData = data?.payload?.items.map((coupon: any) => ({
        ...coupon,
        checked: false
      }))
      // setCoupons(data?.payload?.items)
      localStorage.setItem('coupons', JSON.stringify(modifiedCouponData))
      setCoupons(modifiedCouponData)
      setFuelCoupons(modifiedCouponData)
      setIsCheckedAll(false)
      // setCouponsArray(data?.payload?.items)
      setTotal(data?.payload?.total)
    } catch (e) {
      //handle error
    } finally {
      setLoading(false)
    }
  }

  const getUsedCoupons = async (
    multipleOwnership: boolean,
    fromDate: Date | null,
    toDate: Date | null,
    isSelf: boolean,
    isOthers: boolean,
    isAnother: boolean,
    isUsed: boolean,
    isPending: boolean,
    lim: number
  ) => {
    const filters: FilterType[] = []
    if (isFilters) {
      setIsFilters(false)
    }
    if (isSelf) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isOthers) {
      filters.push(
        {
          f: 'owner.id',
          o: '!=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isAnother) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '!=',
          p: [`${userId}`]
        }
      )
    }

    if (isUsed) {
      filters.push({
        f: 'status.code',
        o: '=',
        p: 'USED'
      })
    }

    if (isPending) {
      filters.push({
        f: 'payment_status.code',
        o: '=',
        p: 'PENDING'
      })
    }

    if ((fromDate && toDate) || fromDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`
        ]
      })
    }

    setLoading(true)

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_USED_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(filters)}&limit=${lim}`
      )
      if (filters.length > 0) {
        setIsFilters(true)
      }
      const modifiedCouponData = data?.payload?.items.map((coupon: any) => ({
        ...coupon,
        checked: false
      }))
      // setCoupons(data?.payload?.items)
      localStorage.setItem('coupons', JSON.stringify(modifiedCouponData))
      setCoupons(modifiedCouponData)
      setFuelCoupons(modifiedCouponData)
      setIsCheckedAll(false)
      // setCouponsArray(data?.payload?.items)
      setTotal(data?.payload?.total)
    } catch (e) {
      //handle error
    } finally {
      setLoading(false)
    }
  }

  const getPendingCoupons = async (
    multipleOwnership: boolean,
    fromDate: Date | null,
    toDate: Date | null,
    isSelf: boolean,
    isOthers: boolean,
    isAnother: boolean,
    isUsed: boolean,
    isPending: boolean,
    lim: number
  ) => {
    const filters: FilterType[] = []
    if (isFilters) {
      setIsFilters(false)
    }

    if (isSelf) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isOthers) {
      filters.push(
        {
          f: 'owner.id',
          o: '!=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isAnother) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '!=',
          p: [`${userId}`]
        }
      )
    }

    if (isUsed) {
      filters.push({
        f: 'status.code',
        o: '=',
        p: 'USED'
      })
    }

    if (isPending) {
      filters.push({
        f: 'payment_status.code',
        o: '=',
        p: 'PENDING'
      })
    }

    if ((fromDate && toDate) || fromDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`
        ]
      })
    }

    setLoading(true)

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_PENDING_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(filters)}&limit=${lim}`
      )
      if (filters.length > 0) {
        setIsFilters(true)
      }
      const modifiedCouponData = data?.payload?.items.map((coupon: any) => ({
        ...coupon,
        checked: false
      }))
      // setCoupons(data?.payload?.items)
      localStorage.setItem('coupons', JSON.stringify(modifiedCouponData))
      setCoupons(modifiedCouponData)
      setFuelCoupons(modifiedCouponData)
      setIsCheckedAll(false)
      // setCouponsArray(data?.payload?.items)
      setTotal(data?.payload?.total)
    } catch (e) {
      //handle error
    } finally {
      setLoading(false)
    }
  }

  //getting coupon for others
  const getOthersCoupons = async (
    multipleOwnership: boolean,
    fromDate: Date | null,
    toDate: Date | null,
    isSelf: boolean,
    isOthers: boolean,
    isAnother: boolean,
    isUsed: boolean,
    isPending: boolean,
    lim: number
  ) => {
    const filters: FilterType[] = []
    if (isFilters) {
      setIsFilters(false)
    }

    if (isSelf) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isOthers) {
      filters.push(
        {
          f: 'owner.id',
          o: '!=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isAnother) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '!=',
          p: [`${userId}`]
        }
      )
    }

    if (isUsed) {
      filters.push({
        f: 'status.code',
        o: '=',
        p: 'USED'
      })
    }

    if (isPending) {
      filters.push({
        f: 'payment_status.code',
        o: '=',
        p: 'PENDING'
      })
    }

    if ((fromDate && toDate) || fromDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`
        ]
      })
    }

    setLoading(true)

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_OTHER_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(filters)}&limit=${lim}`
      )

      if (filters.length > 0) {
        setIsFilters(true)
      }
      const modifiedCouponData = data?.payload?.items.map((coupon: any) => ({
        ...coupon,
        checked: false
      }))
      // setCoupons(data?.payload?.items)
      localStorage.setItem('coupons', JSON.stringify(modifiedCouponData))
      setIsCheckedAll(false)
      setCoupons(modifiedCouponData)
      setFuelCoupons(modifiedCouponData)
      // setCouponsArray(data?.payload?.items)
      setTotal(data?.payload?.total)
    } catch (e) {
      //handle error
    } finally {
      setLoading(false)
    }
  }

  //logic for getting discounted coupons
  const getDiscountCoupons = async (
    multipleOwnership: boolean,
    fromDate: Date | null,
    toDate: Date | null,
    isSelf: boolean,
    isOthers: boolean,
    isAnother: boolean,
    isUsed: boolean,
    isPending: boolean,
    lim: number
  ) => {
    const filters: FilterType[] = []
    if (isFilters) {
      setIsFilters(false)
    }

    if (isSelf) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isOthers) {
      filters.push(
        {
          f: 'owner.id',
          o: '!=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '=',
          p: [`${userId}`]
        }
      )
    }

    if (isAnother) {
      filters.push(
        {
          f: 'owner.id',
          o: '=',
          p: [`${userId}`],
          c: multipleOwnership ? 'OR' : 'AND'
        },
        {
          f: 'creator.id',
          o: '!=',
          p: [`${userId}`]
        }
      )
    }

    if (isUsed) {
      filters.push({
        f: 'status.code',
        o: '=',
        p: 'USED'
      })
    }

    if (isPending) {
      filters.push({
        f: 'payment_status.code',
        o: '=',
        p: 'PENDING'
      })
    }

    if ((fromDate && toDate) || fromDate) {
      filters.push({
        f: 'created_at',
        o: 'between',
        p: [
          `${fromDate?.toISOString() ?? moment().toISOString()}`,
          `${toDate?.toISOString() ?? moment().toISOString()}`
        ]
      })
    }

    setLoading(true)

    try {
      const { data } = await BaseService.get_api(
        `${RouteConstants.MY_DISCOUNT_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(filters)}&limit=${lim}`
      )
      const discountResp = await BaseService.get_api(
        `${RouteConstants.MY_DISCOUNT_STATS}`
      )
      if (filters.length > 0) {
        setIsFilters(true)
      }
      setCoupons(data?.payload?.items)
      setDiscountStats(discountResp.data?.payload)
      setTotal(data?.payload?.total)
    } catch (e) {
      //handle error
    } finally {
      setLoading(false)
    }
  }

  const handleTitleChange = () => {
    switch (title) {
      case 'archive':
        setPageTitle('Archived coupons')
        getArchivedCoupons(
          isMultipleOwnership,
          fromDate,
          toDate,
          selfOwnership,
          othersOwnership,
          anotherOwnership,
          isCouponUsed,
          isCouponPending,
          limit
        )
        break
      case 'available':
        setPageTitle('Available coupons')
        getAvailableCoupons(
          isMultipleOwnership,
          fromDate,
          toDate,
          selfOwnership,
          othersOwnership,
          anotherOwnership,
          isCouponUsed,
          isCouponPending,
          limit
        )
        break
      case 'pending':
        setPageTitle('Pending payments')
        getPendingCoupons(
          isMultipleOwnership,
          fromDate,
          toDate,
          selfOwnership,
          othersOwnership,
          anotherOwnership,
          isCouponUsed,
          isCouponPending,
          limit
        )
        break
      case 'used':
        setPageTitle('Used coupons')
        getUsedCoupons(
          isMultipleOwnership,
          fromDate,
          toDate,
          selfOwnership,
          othersOwnership,
          anotherOwnership,
          isCouponUsed,
          isCouponPending,
          limit
        )
        break
      case 'others':
        setPageTitle('Others coupons')
        getOthersCoupons(
          isMultipleOwnership,
          fromDate,
          toDate,
          selfOwnership,
          othersOwnership,
          anotherOwnership,
          isCouponUsed,
          isCouponPending,
          limit
        )
        break
      case 'discounts':
        setPageTitle('Discount')
        getDiscountCoupons(
          isMultipleOwnership,
          fromDate,
          toDate,
          selfOwnership,
          othersOwnership,
          anotherOwnership,
          isCouponUsed,
          isCouponPending,
          limit
        )
        break
      default:
        setPageTitle('All coupons')
    }
  }

  //get coupon stats
  const fetchStats = async () => {
    try {
      const response = await BaseService.get_api(`${RouteConstants.ALL_STATS}`)
      const userStats = response.data?.payload
      const bought4OthersResp = await BaseService.get_api(
        `${RouteConstants.MY_OTHER_STATS}`
      )
      const pendingPaymentResp = await BaseService.get_api(
        `${RouteConstants.MY_PENDING_PAYMENT_COUPONS}`
      )
      setBought4Others(bought4OthersResp.data?.payload)
      setPendingPayment(pendingPaymentResp.data?.payload)
      // console.log(userStats)
      setUserStats(userStats)
    } catch (error) {
      // console.log(error)
    }
  }

  //onLoadMore
  const onLoadMore = () => {
    if (limit < total) {
      setLimit(limit + 12)
      setIsCheckedAll(false)
    }
  }

  useEffect(() => {
    const searchedCoupons = JSON.parse(localStorage.getItem('searched') || '[]')
    if (searchedCoupons.length > 0) {
      setCoupons(searchedCoupons)
      setFuelCoupons(searchedCoupons)
      // localStorage.setItem('coupons', JSON.stringify(searchedCoupons))
    } else {
      handleTitleChange()
    }
    // handleTitleChange()
    fetchStats()
    setIsCheckedAll(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])

  const onCouponSelect = (selectedId: any) => {
    if (searchTerm.trim() !== '' || isFilters) {
      const couponsCopy = [...coupons]
      localStorage.setItem('searched', JSON.stringify(couponsCopy))
    }
    navigate(`/coupons/${selectedId}`)
  }

  const payCouponHandler = () => {
    navigate('/payment')
  }

  let couponAmount: any = 0
  let couponQuantity: any = 0

  if (title === 'archive') {
    if (isFilters || searchTerm !== '') {
      coupons.forEach((coupon: any) => {
        couponAmount += parseFloat(coupon.price)
      })
      couponQuantity = coupons.length || 0
    } else {
      couponAmount = userStats?.total_amounts
      couponQuantity = userStats?.total_coupons || 0
    }
  } else if (title === 'available') {
    if (isFilters || searchTerm !== '') {
      coupons.forEach((coupon: any) => {
        couponAmount += parseFloat(coupon.amount_left)
      })
      couponQuantity = coupons.length || 0
    } else {
      couponAmount = userStats?.total_coupons_available_amount
      couponQuantity = userStats?.total_coupons_available || 0
    }
  } else if (title === 'others') {
    if (isFilters || searchTerm !== '') {
      coupons.forEach((coupon: any) => {
        couponAmount += parseFloat(coupon.price)
      })
      couponQuantity = coupons.length || 0
    } else {
      couponAmount = bought4Others?.total_amount_bought_for_others
      couponQuantity = bought4Others?.total_number_bought_for_others || 0
    }
  } else if (title === 'discounts') {
    if (isFilters || searchTerm !== '') {
      coupons.forEach((coupon: any) => {
        couponAmount += parseFloat(coupon?.amount)
      })
      couponQuantity = coupons.length || 0
    } else {
      couponAmount = discountStats?.total_discount_amount
      couponQuantity = discountStats?.total_discount_number || 0
    }
  } else if (title === 'used') {
    if (isFilters || searchTerm !== '') {
      coupons.forEach((coupon: any) => {
        couponAmount += parseFloat(coupon.price)
      })
      couponQuantity = coupons.length || 0
    } else {
      couponAmount = userStats?.total_coupons_used_amount
      couponQuantity = userStats?.total_coupons_used || 0
    }
  } else if (title === 'pending') {
    if (isFilters || searchTerm !== '') {
      coupons.forEach((coupon: any) => {
        couponAmount += parseFloat(coupon.price)
      })
      couponQuantity = coupons.length || 0
    } else {
      couponAmount = pendingPayment?.total_coupons_pending_payments_amount
      couponQuantity = pendingPayment?.total_coupons_pending_payments || 0
    }
  }

  const handleFuelType = async (fuelType: string) => {
    const couponsCopy = [...fuelCoupons]

    let filteredCoupons: any[] = []
    if (fuelType === 'super') {
      filteredCoupons = couponsCopy.filter(
        (coupon: any) => coupon.fuel_type.code === 'SUPER'
      )
    } else if (fuelType === 'diesel') {
      filteredCoupons = couponsCopy.filter(
        (coupon: any) => coupon.fuel_type.code === 'DIESEL'
      )
    } else if (fuelType === 'v-power') {
      filteredCoupons = couponsCopy.filter(
        (coupon: any) => coupon.fuel_type.code === 'V_POWER'
      )
    } else {
      filteredCoupons = couponsCopy
    }
    const modifiedFilteredCoupons = filteredCoupons.map((coupon: any) => ({
      ...coupon,
      checked: false
    }))
    setIsCheckedAll(false)
    setFuelType(fuelType)
    setCoupons(modifiedFilteredCoupons)
    setIsFilters(true)
    setSelectedCoupons([])
  }

  const debouncedCouponFilters = debounce(async (search: string) => {
    setispining(true)

    if (title === 'available') {
      setFuelType('all')
    }
    const searchFilter = [
      {
        f: 'creator.name',
        o: 'contains',
        p: [`${search}`],
        c: 'OR'
      },
      {
        f: 'owner.name',
        o: 'contains',
        p: [`${search}`],
        c: 'OR'
      },
      {
        f: 'reference',
        o: 'contains',
        p: [`${search}`]
      }
    ]

    let searchURL = ''
    if (title === 'archive') {
      searchURL = `${RouteConstants.MY_ARCHIVED_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(searchFilter)}`
    } else if (title === 'available') {
      searchURL = `${RouteConstants.MY_AVAILABLE_PAID_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(searchFilter)}`
    } else if (title === 'used') {
      searchURL = `${RouteConstants.MY_USED_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(searchFilter)}`
    } else if (title === 'pending') {
      searchURL = `${RouteConstants.MY_PENDING_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(searchFilter)}`
    } else if (title === 'others') {
      searchURL = `${RouteConstants.MY_OTHER_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(searchFilter)}`
    } else if (title === 'discounts') {
      searchURL = `${RouteConstants.MY_DISCOUNT_COUPONS}?sorting=created_at:desc&filters=${JSON.stringify(searchFilter)}`
    }

    try {
      const { data } = await BaseService.get_api(searchURL)

      const modifiedCouponData = data?.payload?.items.map((coupon: any) => ({
        ...coupon,
        checked: false
      }))
      // setCoupons(data?.payload?.items)
      if (search === '' && modifiedCouponData.length === 0) {
        const storedCoupons = JSON.parse(
          localStorage.getItem('coupons') || '[]'
        )
        setCoupons(storedCoupons)
        // setFuelCoupons(storedCoupons)
      } else {
        setCoupons(modifiedCouponData)
        // setFuelCoupons(modifiedCouponData)
      }

      setIsCheckedAll(false)
      // setCouponsArray(data?.payload?.items)
      setTotal(data?.payload?.total)
    } catch (e) {
      //handle error
    } finally {
      setispining(false)
      setIsCheckedAll(false)
    }
  }, 300)

  //add coupon to merge list
  const addToListHandler = (id: string) => {
    const couponsCopy = [...coupons]
    const fuelCouponsCopy = [...fuelCoupons]
    const updatedCoupons = couponsCopy.map((coupon: any) =>
      coupon.id === id ? { ...coupon, checked: true } : coupon
    )
    const updatedfuelCoupons = fuelCouponsCopy.map((coupon: any) =>
      coupon.id === id ? { ...coupon, checked: true } : coupon
    )
    const selectedCoupon = couponsCopy.find((coupon: any) => coupon.id === id)
    const couponsToMerge = [...selectedCoupons, selectedCoupon]
    setCoupons(updatedCoupons)
    setFuelCoupons(updatedfuelCoupons)
    setSelectedCoupons(couponsToMerge)
  }

  //remove from merge list
  const removeFromListHandler = (id: string) => {
    const updatedCoupons = [...coupons].map((coupon: any) =>
      coupon.id === id ? { ...coupon, checked: false } : coupon
    )

    const fuelCouponsCopy = [...fuelCoupons]
    const updatedfuelCoupons = fuelCouponsCopy.map((coupon: any) =>
      coupon.id === id ? { ...coupon, checked: false } : coupon
    )

    const couponsToMerge = [...selectedCoupons].filter(
      (coupon: any) => coupon.id !== id
    )
    setCoupons(updatedCoupons)
    setFuelCoupons(updatedfuelCoupons)
    setSelectedCoupons(couponsToMerge)
    setIsCheckedAll(false)
  }

  const mergeCouponsHandler = async () => {
    setIsMerging('merging')
    const selectedCouponIds = selectedCoupons.map((coupon: any) => coupon.id)
    const mergeData = {
      coupon_ids: selectedCouponIds
    }
    try {
      const response = await BaseService.post_api(
        RouteConstants.MERGE_COUPONS,
        mergeData
      )
      setIsMerging('merged')
      //console.log(response)
      setMergedCoupon(response.data.payload)
    } catch (error: any) {
      // console.log(error.response)
      setIsMerging('merge')
      setShowMergePrompt(false)
      openNotification('error', error.response.data.message)
    }
  }

  const archiveCouponsHandler = async () => {
    setIsArchiving('archiving')
    const selectedCouponIds = selectedCoupons.map((coupon: any) => coupon.id)
    const archiveData = {
      coupon_ids: selectedCouponIds
    }
    try {
      if (title === 'archive') {
        await BaseService.post_api(
          RouteConstants.UNARCHIVE_COUPONS,
          archiveData
        )
      } else {
        await BaseService.post_api(RouteConstants.ARCHIVE_COUPONS, archiveData)
      }

      setIsArchiving('archived')
      //console.log(response)
    } catch (error: any) {
      // console.log(error.response)
      setIsArchiving('archive')
      setShowArchivePrompt(false)
      openNotification('error', error.response.data.message)
    }
  }

  const handleCancelMerge = () => {
    const fuelCouponsCopy = [...fuelCoupons]
    const modifiedFuelCopy = fuelCouponsCopy.map((coupon: any) =>
      coupon.checked ? { ...coupon, checked: false } : coupon
    )
    setCoupons(modifiedFuelCopy)
    setFuelCoupons(modifiedFuelCopy)
    setShowMergePrompt(false)
    setFuelType('all')
    setIsMerging('merge')
    setSelectedCoupons([])
  }

  const handleCancelArchive = () => {
    const fuelCouponsCopy = [...fuelCoupons]
    const modifiedFuelCopy = fuelCouponsCopy.map((coupon: any) =>
      coupon.checked ? { ...coupon, checked: false } : coupon
    )
    setCoupons(modifiedFuelCopy)
    setFuelCoupons(modifiedFuelCopy)
    setShowArchivePrompt(false)
    setIsArchiving('archive')
    setSelectedCoupons([])
  }

  const viewMergedCoupon = () => {
    navigate(`/coupons/${mergedCoupon.id}`)
  }

  useEffect(() => {
    if (searchTerm !== '') {
      debouncedCouponFilters(searchTerm)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  const archiveSuccessHandler = () => {
    setShowArchivePrompt(false)
    setIsArchiving('archived')
    setSelectedCoupons([])
    // setArchiveCoupons(false)
    navigate('/coupons')
  }

  const checkBoxHandler: CheckboxProps['onChange'] = (e) => {
    if (e.target.checked) {
      const couponsCopy = [...fuelCoupons]
      let modifiedCouponData = []
      if (title === 'available') {
        if (fuelType === 'super') {
          modifiedCouponData = couponsCopy
            .map((coupon: any) => ({
              ...coupon,
              checked: true
            }))
            .filter((couponItem: any) => couponItem.fuel_type.code === 'SUPER')
        }
        if (fuelType === 'diesel') {
          modifiedCouponData = couponsCopy
            .map((coupon: any) => ({
              ...coupon,
              checked: true
            }))
            .filter((couponItem: any) => couponItem.fuel_type.code === 'DIESEL')
        }
        if (fuelType === 'v-power') {
          modifiedCouponData = couponsCopy
            .map((coupon: any) => ({
              ...coupon,
              checked: true
            }))
            .filter(
              (couponItem: any) => couponItem.fuel_type.code === 'V_POWER'
            )
        }
        if (fuelType === 'all') {
          modifiedCouponData = couponsCopy.map((coupon: any) => coupon)
        }
      } else {
        modifiedCouponData = couponsCopy.map((coupon: any) => ({
          ...coupon,
          checked: true
        }))
      }

      // setCoupons(data?.payload?.items)
      setIsCheckedAll(true)
      setCoupons(modifiedCouponData)
      //setFuelCoupons(modifiedCouponData)
      setSelectedCoupons(modifiedCouponData)
    } else {
      // removeFromList(coupon.id)
      const couponsCopy = [...fuelCoupons]
      // const modifiedCouponData = couponsCopy.map((coupon: any) => ({
      //   ...coupon,
      //   checked: false
      // }))
      let modifiedCouponData = []
      if (title === 'available') {
        if (fuelType === 'super') {
          modifiedCouponData = couponsCopy
            .map((coupon: any) => ({
              ...coupon,
              checked: false
            }))
            .filter((couponItem: any) => couponItem.fuel_type.code === 'SUPER')
        }
        if (fuelType === 'diesel') {
          modifiedCouponData = couponsCopy
            .map((coupon: any) => ({
              ...coupon,
              checked: false
            }))
            .filter((couponItem: any) => couponItem.fuel_type.code === 'DIESEL')
        }
        if (fuelType === 'v-power') {
          modifiedCouponData = couponsCopy
            .map((coupon: any) => ({
              ...coupon,
              checked: false
            }))
            .filter(
              (couponItem: any) => couponItem.fuel_type.code === 'V_POWER'
            )
        }
        if (fuelType === 'all') {
          modifiedCouponData = couponsCopy.map((coupon: any) => coupon)
        }
      } else {
        modifiedCouponData = couponsCopy.map((coupon: any) => ({
          ...coupon,
          checked: false
        }))
      }
      // setCoupons(data?.payload?.items)
      setIsCheckedAll(false)
      setCoupons(modifiedCouponData)
      // setFuelCoupons(modifiedCouponData)
      setSelectedCoupons([])
      setIsCheckedAll(false)
    }
  }

  useEffect(() => {
    if (coupons.length === selectedCoupons.length) {
      setIsCheckedAll(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCoupons])

  const handleBackNavigation = () => {
    localStorage.removeItem('searched')
    navigate(-1)
  }
  // console.log(coupons)
  return (
    <Fragment>
      {contextHolder}
      <div className='bg-neutral-50 relative h-screen overflow-y-auto dark:bg-dark-100'>
        <div className='flex gap-x-3 items-center mx-[20px] mt-5'>
          <div className='flex-[0.1]'>
            <button
              className='h-10 text-neutral-300 flex justify-center items-center'
              onClick={handleBackNavigation}
            >
              <ChevronLeftIcon className='h-6 w-6 font-bold' />
            </button>
          </div>
          <div className='flex-[0.8] text-center'>
            <h4 className='text-header-4'>{pageTitle}</h4>
          </div>
          {title === 'discounts' && (
            <div className='flex flex-[0.1] w-full justify-end items-end '>
              <button
                className='flex items-center gap-1 '
                onClick={() => {
                  setOpenFilter(true)
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <path
                    d='M3.59993 1.40039H12.3999C13.1333 1.40039 13.7333 2.00039 13.7333 2.73372V4.20039C13.7333 4.73372 13.3999 5.40039 13.0666 5.73372L10.1999 8.26706C9.79993 8.60039 9.53327 9.26706 9.53327 9.80039V12.6671C9.53327 13.0671 9.2666 13.6004 8.93327 13.8004L7.99994 14.4004C7.13327 14.9337 5.93327 14.3337 5.93327 13.2671V9.73372C5.93327 9.26706 5.6666 8.66706 5.39994 8.33372L2.8666 5.66706C2.53327 5.33372 2.2666 4.73372 2.2666 4.33372V2.80039C2.2666 2.00039 2.8666 1.40039 3.59993 1.40039Z'
                    stroke='#A53D00'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M7.28667 1.40039L4 6.66706'
                    stroke='#A53D00'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
                <div className='btn-subtle'>Filter</div>
              </button>
            </div>
          )}
          {/* {(title === 'pending' ||
            title === 'used' ||
            title === 'others' ||
            title === 'archive') && (
            <Dropdown
              menu={{ items }}
              trigger={['click']}
              placement='bottomCenter'
            >
              <button onClick={(e) => e.preventDefault()}>
                <Space>
                  <svg
                    width='14'
                    height='4'
                    viewBox='0 0 14 4'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    className='cursor-pointer'
                  >
                    <path
                      d='M3.94667 2.00005C3.94667 0.910213 3.06318 0.026722 1.97333 0.026722C0.883491 0.026722 0 0.910213 0 2.00005C0 3.0899 0.883491 3.97339 1.97333 3.97339C3.06318 3.97339 3.94667 3.0899 3.94667 2.00005Z'
                      fill='#484442'
                    />
                    <path
                      d='M8.55995 2.00006C8.55995 0.910213 7.67646 0.026722 6.58661 0.026722C5.49677 0.026722 4.61328 0.910213 4.61328 2.00006C4.61328 3.0899 5.49677 3.97339 6.58661 3.97339C7.67646 3.97339 8.55995 3.0899 8.55995 2.00006Z'
                      fill='#484442'
                    />
                    <path
                      d='M13.1732 2.00006C13.1732 0.910213 12.2897 0.026722 11.1999 0.026722C10.1101 0.026722 9.22656 0.910213 9.22656 2.00006C9.22656 3.0899 10.1101 3.97339 11.1999 3.97339C12.2897 3.97339 13.1732 3.0899 13.1732 2.00006Z'
                      fill='#484442'
                    />
                  </svg>
                </Space>
              </button>
            </Dropdown>
          )} */}
        </div>

        {(title === 'used' ||
          title === 'available' ||
          title === 'pending' ||
          title === 'others' ||
          title === 'archive') && (
          <div className='flex justify-between items-center mx-5 mt-5 mb-3 gap-x-2'>
            <Input
              placeholder='Search by name or serial number'
              size='large'
              prefix={
                ispining ? (
                  <div className='loader'></div>
                ) : (
                  <svg
                    width='20'
                    height='20'
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M9.58317 18.1251C4.87484 18.1251 1.0415 14.2917 1.0415 9.58342C1.0415 4.87508 4.87484 1.04175 9.58317 1.04175C14.2915 1.04175 18.1248 4.87508 18.1248 9.58342C18.1248 14.2917 14.2915 18.1251 9.58317 18.1251ZM9.58317 2.29175C5.55817 2.29175 2.2915 5.56675 2.2915 9.58342C2.2915 13.6001 5.55817 16.8751 9.58317 16.8751C13.6082 16.8751 16.8748 13.6001 16.8748 9.58342C16.8748 5.56675 13.6082 2.29175 9.58317 2.29175Z'
                      fill='#939291'
                    />
                    <path
                      d='M18.3335 18.9583C18.1752 18.9583 18.0169 18.9 17.8919 18.775L16.2252 17.1083C15.9835 16.8666 15.9835 16.4666 16.2252 16.225C16.4669 15.9833 16.8669 15.9833 17.1085 16.225L18.7752 17.8916C19.0169 18.1333 19.0169 18.5333 18.7752 18.775C18.6502 18.9 18.4919 18.9583 18.3335 18.9583Z'
                      fill='#939291'
                    />
                  </svg>
                )
              }
              style={{ width: '85%' }}
              value={searchTerm}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchTerm(e.target.value)
              }
            />
            <div className='flex flex-[0.1] w-full justify-end items-end mr-3'>
              <button
                onClick={() => {
                  setOpenFilter(true)
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <path
                    d='M3.59993 1.40039H12.3999C13.1333 1.40039 13.7333 2.00039 13.7333 2.73372V4.20039C13.7333 4.73372 13.3999 5.40039 13.0666 5.73372L10.1999 8.26706C9.79993 8.60039 9.53327 9.26706 9.53327 9.80039V12.6671C9.53327 13.0671 9.2666 13.6004 8.93327 13.8004L7.99994 14.4004C7.13327 14.9337 5.93327 14.3337 5.93327 13.2671V9.73372C5.93327 9.26706 5.6666 8.66706 5.39994 8.33372L2.8666 5.66706C2.53327 5.33372 2.2666 4.73372 2.2666 4.33372V2.80039C2.2666 2.00039 2.8666 1.40039 3.59993 1.40039Z'
                    stroke='#A53D00'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M7.28667 1.40039L4 6.66706'
                    stroke='#A53D00'
                    stroke-width='1.5'
                    stroke-miterlimit='10'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </button>
            </div>
          </div>
        )}

        <div className='mt-3 mx-[20px]'>
          {/* {title === 'discounts' ||
          (title === 'available' && fuelType === 'all') ? (
            <></>
          ) : (
            <div className='text-primary font-bold mb-3 flex justify-end'>
              Select all{' '}
              <Checkbox
                className='accent-[#A53D00]'
                style={{
                  marginLeft: 5,
                  marginRight: 12
                }}
                onChange={checkBoxHandler}
                checked={isCheckedAll}
              />
            </div>
          )} */}

          <>
            {loading ? (
              <div className='w-full animate-pulse'>
                <div className='bg-gray-400 rounded-md mb-2 h-[100px] w-full' />
                <div className='bg-gray-400 rounded-md mb-2 h-[100px] w-full' />
                <div className='bg-gray-400 rounded-md mb-2 h-[100px] w-full' />
              </div>
            ) : (
              <>
                {title === 'archive' ? (
                  <></>
                ) : (
                  <>
                    {title === 'available' && cta && (
                      <CallToAction onClose={() => setCTA(false)} />
                    )}
                    <div className='py-3 px-5 shadow bg-white rounded-lg mt-6 mb-3 flex justify-between items-center text-sm dark:bg-dark-100 dark:text-white dark:shadow-[#424141] dark:shadow-sm'>
                      <div>
                        <p className='#484442'>Number of coupons</p>
                        <p className=' font-bold text-[#939291]'>
                          {couponQuantity}
                        </p>
                      </div>
                      <div>
                        <p className='#484442'>
                          {title === 'discounts'
                            ? 'Total discount'
                            : 'Total amount'}
                        </p>
                        {couponAmount ? (
                          <p className=' font-bold text-[#939291]'>
                            <span> GH&#8373;</span>
                            {(couponAmount * 1).toFixed(2)}
                          </p>
                        ) : (
                          <Skeleton
                            active={true}
                            paragraph={{
                              rows: 1,
                              width: 73,
                              style: { borderRadius: 3, height: 11 }
                            }}
                            title={false}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )}
                <div
                  className={`flex items-center ${title === 'available' ? 'justify-between' : 'justify-end'} mb-3`}
                >
                  {title === 'available' && (
                    <div className='flex gap-x-3 items-center'>
                      {fuelButtons.map((btn) => (
                        <button
                          className={`rounded-lg text-xs ${btn.id === fuelType ? 'bg-[#FFE0CC] text-[#A53D00]' : 'bg-white text-[#939291]'}  w-fit p-[6px]`}
                          onClick={() => handleFuelType(btn.id)}
                        >
                          {btn.name}
                        </button>
                      ))}
                    </div>
                  )}
                  {title === 'discounts' ||
                  (title === 'available' && fuelType === 'all') ||
                  coupons?.length === 0 ? (
                    <></>
                  ) : (
                    <div className='text-primary font-bold flex justify-end'>
                      Select all{' '}
                      <Checkbox
                        className='accent-[#A53D00]'
                        style={{
                          marginLeft: 5,
                          marginRight: 12
                        }}
                        onChange={checkBoxHandler}
                        checked={isCheckedAll}
                      />
                    </div>
                  )}
                </div>
                {coupons?.length > 0 ? (
                  <>
                    {coupons.map((item: any, index: number) =>
                      title === 'discounts' ? (
                        <DiscountCard
                          key={item.id}
                          coupon={item}
                          handleSelect={() =>
                            navigate(`/coupons/${item.coupon.id}`)
                          }
                        />
                      ) : (
                        <CouponCard
                          key={item.id}
                          coupon={item}
                          onCouponSelect={() => onCouponSelect(item.id)}
                          payCoupon={payCouponHandler}
                          title={title}
                          fuelType={fuelType}
                          addToList={addToListHandler}
                          removeFromList={removeFromListHandler}
                          // archive={archiveCoupons}
                        />
                      )
                    )}
                  </>
                ) : (
                  <div className='flex flex-col justify-center items-center h-full'>
                    <img
                      className='mt-10 lg:w-[300px]'
                      src={boxImg}
                      alt='empty'
                    />
                    <div className='text-center'>
                      You have no {title} coupons yet.{' '}
                      <button
                        onClick={() => setOpenBottom(true)}
                        className='text-[#5C8BFF] font-bold'
                      >
                        Click here
                      </button>{' '}
                      to buy coupon.
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>

        {limit >= total ? (
          <></>
        ) : (
          <div className='pb-14 flex justify-center items-center'>
            <Button onClick={() => onLoadMore()} disabled={limit >= total}>
              <div className='flex items-center gap-2'>
                View More
                <ChevronDoubleDownIcon className='h-4 w-4' />
              </div>
            </Button>
          </div>
        )}
      </div>
      <FilterModal
        isOpen={openFilter}
        handleOpenDrawer={() => setOpenFilter(false)}
        type={title}
        handleReset={() => {
          setFromDate(null)
          setToDate(null)
          setOpenFilter(false)
          setSelfOwnership(false)
          setOthersOwnership(false)
          setAnotherOwnership(false)
          setIsMultipleOwnership(false)
          setIsCouponUsed(false)
          setIsCouponPending(false)

          switch (title) {
            case 'archive':
              getArchivedCoupons(
                false,
                null,
                null,
                false,
                false,
                false,
                false,
                false,
                12
              )
              break
            case 'available':
              getAvailableCoupons(
                false,
                null,
                null,
                false,
                false,
                false,
                false,
                false,
                12
              )
              break
            case 'pending':
              getPendingCoupons(
                false,
                null,
                null,
                false,
                false,
                false,
                false,
                false,
                12
              )
              break
            case 'others':
              getOthersCoupons(
                false,
                null,
                null,
                false,
                false,
                false,
                false,
                false,
                12
              )
              break
            case 'discounts':
              getDiscountCoupons(
                false,
                null,
                null,
                false,
                false,
                false,
                false,
                false,
                12
              )
              break
            case 'used':
              getUsedCoupons(
                false,
                null,
                null,
                false,
                false,
                false,
                false,
                false,
                12
              )
              break
            default:
          }
        }}
        handleFromDate={(date) => {
          setFromDate(date)
        }}
        handleToDate={(date) => setToDate(date)}
        applyFilter={(
          isMultiple,
          fromDate,
          toDate,
          self,
          others,
          another,
          used,
          pending,
          lim
        ) => {
          switch (title) {
            case 'archive':
              getArchivedCoupons(
                isMultiple,
                fromDate,
                toDate,
                self,
                others,
                another,
                used,
                pending,
                lim
              )
              break
            case 'available':
              getAvailableCoupons(
                isMultiple,
                fromDate,
                toDate,
                self,
                others,
                another,
                used,
                pending,
                lim
              )
              break
            case 'pending':
              getPendingCoupons(
                isMultiple,
                fromDate,
                toDate,
                self,
                others,
                another,
                used,
                pending,
                lim
              )
              break
            case 'others':
              getOthersCoupons(
                isMultiple,
                fromDate,
                toDate,
                self,
                others,
                another,
                used,
                pending,
                lim
              )
              break
            case 'used':
              getUsedCoupons(
                isMultiple,
                fromDate,
                toDate,
                self,
                others,
                another,
                used,
                pending,
                lim
              )
              break
            case 'discounts':
              getDiscountCoupons(
                isMultiple,
                fromDate,
                toDate,
                self,
                others,
                another,
                used,
                pending,
                lim
              )
              break
            default:
          }
          setOpenFilter(false)
        }}
      />
      {openBottom && (
        <ChooseDrawer
          openDrawer={openBottom}
          handleOpenDrawer={() => setOpenBottom(false)}
        />
      )}
      {openTransfer && (
        <TransferCouponModal
          openModal={openTransfer}
          handleOpenModal={handleOpenTransfer}
          handleCloseModal={handleCloseTransfer}
        />
      )}
      {(fuelType === 'super' ||
        fuelType === 'diesel' ||
        fuelType === 'v-power') && (
        <MergeModal
          handleMerge={() => setShowMergePrompt(true)}
          selectedCoupons={selectedCoupons}
        />
      )}
      {(title === 'used' ||
        title === 'pending' ||
        title === 'others' ||
        title === 'archive') && (
        <ArchiveModal
          handleArchive={() => setShowArchivePrompt(true)}
          selectedCoupons={selectedCoupons}
          title={title}
          handleUnArchive={() => setShowArchivePrompt(true)}
        />
      )}
      {showMergePrompt && (
        <MergePrompt
          coupons={selectedCoupons}
          openPrompt={showMergePrompt}
          cancelMergeHandler={handleCancelMerge}
          acceptMergeHandler={mergeCouponsHandler}
          isMerging={isMerging}
          mergeCouponDetails={viewMergedCoupon}
          handleMerged={() => window.location.reload()}
        />
      )}
      {showArchivePrompt && (
        <ArchivePrompt
          coupons={selectedCoupons}
          openPrompt={showArchivePrompt}
          cancelArchiveHandler={handleCancelArchive}
          acceptArchiveHandler={archiveCouponsHandler}
          isArchiving={isArchiving}
          title={title}
          handleArchive={archiveSuccessHandler}
        />
      )}
    </Fragment>
  )
}

export default Items

import { PhoneInput } from 'react-international-phone'
import { Select } from 'antd'
type BuyOtherUsersProps = {
  phoneError: boolean
  phoneNumber: string
  isBusy: boolean
  nameError: boolean
  firstname: string
  lastname: string
  remove: boolean
  user: number
  fecthingCompanies: boolean
  fetchingTypes: boolean
  allFuelTypes: any[]
  allCompanies: any[]
  userAmount: string
  isMultiple: boolean
  handleOnPhoneChange: (val: any) => void
  addUser: () => void
  removeUser: () => void
  handleUserAmount: (e: React.ChangeEvent<HTMLInputElement>) => void
  onFuelType: (val: any) => void
  onCompany: (val: any) => void
}

function BuyOtherUsers({
  user,
  phoneError,
  phoneNumber,
  handleOnPhoneChange,
  isBusy,
  nameError,
  firstname,
  lastname,
  remove,
  addUser,
  removeUser,
  userAmount,
  isMultiple,
  handleUserAmount,
  fecthingCompanies,
  fetchingTypes,
  allFuelTypes,
  allCompanies,
  onFuelType,
  onCompany
}: BuyOtherUsersProps) {
  return (
    <div className='mt-5'>
      <div>
        <small
          className={`${
            phoneError ? 'text-red-500 ml-1 mb-2' : 'ml-1 mb-2 text-caption'
          }`}
        >
          Person's mobile number
        </small>
        <PhoneInput
          defaultCountry='gh'
          hideDropdown
          placeholder='+233 24 000 0000'
          inputStyle={{
            width: '100%',
            height: '50px',
            fontSize: '18px',
            border: '1px solid #939291',
            padding: '8px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px'
          }}
          value={phoneNumber}
          onChange={(e: any) => handleOnPhoneChange(e)}
          disabled={isBusy}
        />
      </div>

      <div className='mt-5'>
        <small
          className={`${
            nameError ? 'text-red-500 ml-1 mb-2' : 'ml-1 mb-2 text-caption'
          }`}
        >
          Person's first name
        </small>
        <input
          className='input-container w-full focus:outline-none'
          value={firstname}
          onChange={() => {}}
          placeholder="Enter person's first name"
        />
      </div>

      <div className='mt-5'>
        <small
          className={`${
            nameError ? 'text-red-500 ml-1 mb-2' : 'ml-1 mb-2 text-caption'
          }`}
        >
          Person's last name
        </small>
        <input
          className='input-container w-full focus:outline-none '
          value={lastname}
          onChange={() => {}}
          placeholder="Enter person's last name"
        />
      </div>
      {/* {isMultiple && ( */}

      <div className='flex flex-col mt-5'>
        <small>Select fuel company</small>
        <Select
          placeholder='select fuel company'
          className='h-[50px] bg-white rounded-lg border border-gray-500'
          onChange={(e: any) => {
            onCompany(e)
          }}
          // error={selectError}
          disabled={fecthingCompanies}
          options={allCompanies.map((company: any, i: number) => ({
            value: company?.code,
            label: company?.name
          }))}
        />
      </div>
      <div className='mt-5 flex flex-col'>
        <small>Select Fuel Type</small>
        <Select
          placeholder='Select fuel type'
          className='h-[50px] bg-white  rounded-lg border border-gray-500'
          onChange={(e: any) => onFuelType(e)}
          // error={typeErr}
          disabled={fetchingTypes}
          options={allFuelTypes.map((item: any, i: number) => ({
            value: item?.code,
            label: item?.name
          }))}
        />
      </div>

      <div className='mt-5'>
        <small
          className={`${
            nameError ? 'text-red-500 ml-1 mb-2' : 'ml-1 mb-2 text-caption'
          }`}
        >
          Amount to buy
        </small>
        <input
          className='input-container w-full focus:outline-none'
          value={userAmount}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleUserAmount(e)
          }
          placeholder='Enter amount'
          style={{ color: 'black' }}
        />
      </div>

      {/* )} */}
      <div className='mt-5 py-[6px] flex items-center justify-between'>
        <p className='flex items-center gap-x-1 cursor-pointer'>
          <span className='font-medium text-xs text-[#484442]'>
            {remove ? `user ${user + 1}` : 'Buy for more users'}
          </span>
          {!remove && (
            <img
              src='message-question.png'
              alt='question'
              className='w-3 h-3'
            />
          )}
        </p>
        {remove ? (
          <button
            className='text-[#A53D00] py-2 px-[2px] rounded-lg border border-[#A53D00] w-[120px] flex items-center justify-center gap-1'
            onClick={removeUser}
          >
            <img src='/minus.png' alt='minus' />
            <span>Remove user</span>
          </button>
        ) : (
          <button
            className='text-[#A53D00] py-2 px-[2px] rounded-lg border border-[#A53D00] w-[110px] flex items-center justify-center gap-1'
            onClick={addUser}
          >
            <img src='/add.png' alt='add' />
            <span>Add user</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default BuyOtherUsers
